import Vue from 'vue'
import App from './App.vue'
import './assets/css/main.css'
import './assets/style/beasic.css'
import ElementUI from 'element-ui'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import './assets/theme/index.css' // 自定义主题
import VeLine from 'v-charts/lib/line.common' //折线图
import VeBar from 'v-charts/lib/bar.common' // 条形图
import VeHistogram from 'v-charts/lib/histogram.common' //柱状图
import VePie from 'v-charts/lib/pie.common' // 饼图
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
import bus from 'vue-bus'
import './assets/font/iconfont.css'
import Pagination from "@/components/Pagination";

// 全局过滤器
import * as filters from './utils/filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])//插入过滤器名和对应方法
})

// 全局方法
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, getToDay, voicePrompt,getLastTime,getSameMonth } from "@/utils/globalFunc";
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.getToDay = getToDay
Vue.prototype.getLastTime = getLastTime
Vue.prototype.getSameMonth = getSameMonth

// 全局组件挂载
Vue.component('Pagination', Pagination)

Vue.prototype.$layer = layer(Vue);

Vue.config.productionTip = false

Vue.use(ElementUI, {
  size: 'small'
})

Vue.use(bus)

Vue.component(VeLine.name, VeLine)
Vue.component(VeBar.name, VeBar)
Vue.component(VeHistogram.name, VeHistogram)
Vue.component(VePie.name, VePie)


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

