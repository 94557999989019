import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js-cookie'
Vue.use(Router)

// 获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}


const route = new Router({
	mode: "history",
	routes: [
		{
			path: '/',
			redirect: '/login'
		},
		{
			path: '/home',
			component: () => import('../components/Home.vue'),
			meta: { title: 'home' },
			children: [
				{
					path: '/index',
					component: () => import('../pages/dashboard'),
					meta: { title: '系统首页' }
				},
				{
					path: '/orderList',
					component: () => import('../pages/order'),
					meta: { title: '历史订单' },
				},
				{
					path: '/gasSet',
					component: () => import('../pages/gasSet'),
					meta: { title: '油枪调价' },
				},
			]
		},
		{
			path: '/login',
			component: () => import('../pages/login'),
			meta: { title: '登录' }
		},
	]
})


route.beforeEach((to, from, next) => {
	Vue.prototype.$layer.closeAll()
	next()
	if (to.path.indexOf('/login') === -1 && !Cookies.get('token')) {
		next('/')
	} else {
		next()
	}
})

export default route